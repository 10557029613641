import React from "react"
import { globalHistory } from "@reach/router"
import Header from "../components/header/header.component"
import girl from "../images/EmilyJamison.png"
import boy from "../images/ross-otto.jpg"
import SEO from "../components/seo"
import Footer from "../components/footer/footer.component"

const about = () => {
  return (
    <div>
      <SEO title="About " />
      <Header url={globalHistory.location.pathname} />
      <div style={{ background: "#E5E5E5" }} id="top">
        <div className="resource_head">
          <div className="container">
            <div style={{ marginTop: "60px" }}>
              <span class="ourtitle">
                We've built it before
              </span>
            </div>
            <hr />
          </div>
        </div>
        <div className="container resource_title">
          
          <h1>
            <span class="ourtitle">
              LeanML as a Philosophy
            </span>
          </h1>
          <hr style={{ margin: "0 auto" }} />
          <p>
          <span class="ourtext">
            LeanML is the extension of Lean Startup thinking into the realm of
            Machine Learning. Many unique challenges face startup teams with ML
            products, from simulated error testing to software development
            practices. Drive your next ML project to success: start practicing
            LeanML today!
          </span>
          </p>
        </div>
        <div className="container">
          <div style={{ marginTop: "80px" }}></div>
        </div>
        <div className="team">
          <div className="container">
            <div
              style={{
                paddingTop: "100px",
                textAlign: "center",
                paddingBottom: "70px",
              }}
            >
              <h1>
                <span class="ourtitle">
                  Meet Our Team
                </span>
              </h1>
              <hr style={{ margin: "0 auto" }} />
            </div>
            <div className="team_section">
              <div style={{ marginRight: "20px" }}>
                <img src={girl} alt='Emily Jamison'/>
              </div>
              <div>
                <h2>
                  <span class="ourtitle">
                    Emily Jamison
                  </span>
                </h2>
                <hr />
                
                <p
                  style={{
                    color: "black",
                    paddingTop: "17px",
                    paddingBottom: "8px",
                  }}
                >
                  <span class="oursubtitle">
                    NLP PhD
                  </span>
                </p>
                <p>
                <span class="ourtext">
                  Emily Jamison is an enthusiastic supporter of Natural Language
                  Processing and Machine Learning technology produced by
                  startups and other early-stage entities. An ardent believer in
                  the Lean Startup approach to developing ML in environments of
                  extreme uncertainty, she combines tactical team planning with
                  tried-and-true methodology to identify latent customer needs
                  and with the latest bleeding edge research progress. She holds
                  a PhD (German: Dr. rer. nat.) in Computer Science (NLP) and
                  has over 15 years experience in NLP/ML.
                  </span>
                </p>
              </div>
            </div>
            <div
              className="team_section boy"
              style={{
                paddingTop: "120px",
                paddingBottom: "120px",
              }}
            >
              <div>
                <h2>
                  <span class="ourtitle">
                    Ross Otto
                  </span>
                </h2>
                <hr />
                <p
                  style={{
                    color: "black",
                    paddingTop: "17px",
                    paddingBottom: "8px",
                  }}
                >
                  <span class="oursubtitle">
                    Product Architect
                  </span>
                </p>
                <p>
                <span class="ourtext">
                  Ross has a wealth of experience building, designing, launching, and managing products. After cutting his teeth launching an international non-profit to help students around the globe, he has lived and breathed big data and ML products for Fortune 500 companies. Ross holds a BBA in Management and Human Resources from the University of Wisconsin - Madison.
                </span>
                </p>
              </div>
              <div style={{ marginLeft: "20px" }}>
                <img src={boy} alt='Ross Otto' width="350" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer url={globalHistory.location.pathname} />
    </div>
  )
}

export default about
